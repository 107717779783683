export const appHead = {"meta":[{"charset":"utf-8"},{"name":"google","content":"notranslate"},{"http-equiv":"Content-Language","content":"vi"},{"name":"og:site_name","content":"Nhà cái PS03"},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1.0, viewport-fit=cover, user-scalable=no"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/assets/favicon/favicon.ico"},{"rel":"icon","sizes":"16x16","href":"/assets/favicon/favicon-16x16.png"},{"rel":"icon","sizes":"32x32","href":"/assets/favicon/favicon-32x32.png"},{"rel":"apple-touch-icon","sizes":"180x180","href":"/assets/favicon/apple-touch-icon.png"}],"style":[],"script":[],"noscript":[],"htmlAttrs":{"lang":"vi"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"