export const PAGE = {
  HOME: '/',
  GAMES: '/games',
  GAMES_LOTTERY: '/games?type=lottery&provider=all',
  ACCOUNT: '/account',
  ACCOUNT_DEPOSIT: '/account/deposit',
  ACCOUNT_DEPOSIT_BANK: '/account/deposit/bank',
  ACCOUNT_DEPOSIT_DA: '/account/deposit/bank?type=codepay',
  ACCOUNT_DEPOSIT_CODEPAY2: '/account/deposit/bank?type=codepay2',
  ACCOUNT_DEPOSIT_MOMO: '/account/deposit/momo',
  ACCOUNT_DEPOSIT_CARD: '/account/deposit/card',
  ACCOUNT_DEPOSIT_CRYPTO: '/account/deposit/crypto',
  ACCOUNT_PROFILE: '/account/profile',
  ACCOUNT_BANK: '/account/bank',
  ACCOUNT_HISTORY: '/account/history',
  ACCOUNT_PROFIT_TODAY: '/account/profit-today',
  SPORTS_SCHEDULE: '/sports/schedule',
  PROMOTION: '/khuyen-mai',
  PROMOTION_CATEGORY: '/khuyen-mai/the-loai',
  ACCOUNT_WITHDRAW: '/account/withdraw',
  ACCOUNT_WITHDRAW_BANK: '/account/withdraw/bank',
  CASINO: '/livecasino',
  SPORTS: '/the-thao',
  GAME: '/game',
  KSPORT: '/k-sport',
  CSPORT: '/csports',
  SSPORT: '/ssports',
  MSPORT: '/msports',
  ESPORT: '/e-sports',
  TSPORT: '/tsports',
  KSPORTS: '/ksports',
  SUPPORT: '/support',
  VIRTUAL_SPORTS: '/virtual-sports',
  ACCOUNT_REWARD: '/account/bonus',
  ACCOUNT_P2P: '/account/trading-p2p',
  ABOUT_US: '/about-us',
  SPORTS_SCHEDULE_MATCH: '/sports/schedule/match',
  MAINTAIN: '/maintain',
  PAGE404: '/404',
  MAINTENENCE: '/maintenence',
  LODE: '/lode',
  NEWS: '/tin-tuc',
  NEWS_CATEGORY: '/tin-tuc/the-loai',
  QUESTION: '/question',
  INSTRUCTION: '/instruction',
  ACCOUNT_HISTORY_TRANSACTION: '/account/history/transaction',
  ACCOUNT_HISTORY_BET: '/account/history/bet'
}
